body {
  background-color: #ebe9f4;
  // background: #ebe9f4 url('../public/img/background.png') repeat-y scroll 50% 0%;
}
@mixin absolute-fill() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.bg {
  z-index: -1;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  // #ebe9f4  repeat-y scroll 50% 0%
  background-image: url('../public/img/background.png');
  background-repeat: repeat;
  background-position: 50% 0;
  background-size: cover;
}

.main {
  padding: 10vh 20px;
  height: 100vh;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-around;

  h1 {
    display: block;
    text-align: center;
    // justify-content: center;
    height: 120px;
    // line-height: 20vh;
    font-family: 'The Nautigal', cursive;
    font-size: 35px;
    font-weight: 600;
    color: #9784ae;
    text-align: center;
    text-shadow: 2px 2px 4px #00000022;
    margin: 0;
    // background-color: red;
    // b {
    //   font-size: 1em;
    // }
  }

  .subject {
    color: #555;
    font-size: 25px;
    font-weight: '900';
    // font-family: 'IBM Plex Sans KR', sans-serif;
    font-family: 'Hi Melody', cursive;
    text-align: center;
    color: #695383;
    text-shadow: 2px 2px 4px #00000022;
  }

  .date-place {
    margin-bottom: 10px;
    color: #666;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1.2em;
    font-family: 'IBM Plex Sans KR', sans-serif;
    text-align: center;
  }
}

.flower-circle {
  position: relative;
  margin: 0px auto;
  max-width: 320px;
  max-height: 320px;
  .inner {
    @include absolute-fill();
    z-index: 1;
  }

  .inner-hide {
    opacity: 0;
  }

  .content-container {
    @include absolute-fill();
    display: flex;
  }
}

// section
.section-header {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  margin-top: 20px;
  border-top: 1px solid rgba(255, 231, 255, 0.5);
  background-color: #fefefe88;
  padding-top: 20px;
  box-sizing: border-box;

  span {
    font-size: 1.5em;
    color: #333;
    // line-height: 60px;
    font-weight: 600;
    font-family: 'IBM Plex Sans KR', sans-serif;
    text-shadow: 2px 2px 4px #00000022;
  }
}
.section-body {
  background-color: #fefefe88;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(255, 231, 255, 0.5);
  .section-header {
    background-color: transparent !important;
    border-top: none;
  }
  .section-body {
    background-color: transparent !important;
    border-bottom: none;
  }
}

// speach
.speach-container {
  position: relative;
}

.speach {
  font-family: 'IBM Plex Sans KR', sans-serif;
  text-align: center;
  margin: 0;
  font-size: 1em;
  font-weight: 500;
  color: #555;
  text-shadow: 2px 2px 4px #00000022;
}

.speach-main {
  position: absolute;
  z-index: 1;
  margin: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hidden {
  margin: 0;
  color: transparent !important;
  text-shadow: none;
  opacity: 0;
}

#custom-login-btn {
  display: flex;
  align-self: center;
  img {
    margin: 0px auto;
    width: 180px;
  }
}

.comments-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 75px;

  .more {
    background-color: #be3fa3; /* Purple */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 100%;
  }
}

.float-input {
  position: fixed;

  padding: 15px 0px;
  border-top: 1px double rgba(153, 11, 153, 0.397);

  background-color: #fff;
  bottom: 0;
  left: 0;
  right: 0;
  transition: 400ms ease-in-out;
}

.float-input.show {
  transform: translateY(0px);
}

.float-input.hide {
  transform: translateY(2000px);
}

.comment {
  display: flex;
  padding: 5px 16px;
  border-bottom: solid 1px rgba(189, 86, 180, 0.14);
  .comment-auth {
    display: flex;
    flex-direction: column;
  }
  .message-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    .message {
      color: #333;
      font-weight: 500;
      font-family: 'IBM Plex Sans KR', sans-serif;
    }
    .date {
      font-size: 0.5em;
      color: #666;
    }
  }
  button {
    height: 50px;
    border-radius: 0px;
    outline: none;
    border: none;
    background-color: #be3fa3; /* Purple */
    padding: 10px 15px;
    color: white;
    font-size: 1em;
  }
}

.comment.reply {
  margin-left: 35px;
}

.comment-input-container {
  display: flex;
  padding: 0 16px;
  flex-direction: row;
  border-radius: 40px;

  .comment-input-box {
    display: flex;
    flex: 1;
    height: 40px;
    border-radius: 20px 0px 0px 20px;
    box-sizing: border-box;
    border: solid 1px #be3fa3;
    padding: 0px 15px;

    outline: none;
    box-shadow: 0 0 0 0px #be3fa3;
    span {
      display: flex;
      align-self: center;
      color: #333;
      font-size: 1em;
    }
    .comment-input {
      display: flex;
      flex: 1;
      border: none;
      outline: none;
      font-size: 1em;
      color: #333;
    }
  }
  .send {
    background-color: #be3fa3; /* Purple */
    border: none;
    color: white;
    padding: 0px 8px 0px 4px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    width: 65px;
    min-width: 65px;
    border-radius: 0px 20px 20px 0px;
  }
}

.profile {
  border-radius: 40px;
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.share {
  a {
    display: flex;
    text-decoration: none;
    align-items: center;
    padding: 5px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 5px;
    }
  }
  span {
    color: #333;
    font-size: 16px;
    text-decoration: none;
    font-size: 1em;
    font-weight: bold;
    background: #efefef;
  }
}
.map-container {
  position: relative;
  width: 100vw;
  height: 200vw;
  margin-left: -5px;
  .map-overlay {
    z-index: 999;
    @include absolute-fill();
    // background-color: red;
    // opacity: 0.2;
  }
  .map {
    width: 100vw;
    height: 100vw;
  }
  .road {
    width: 100vw;
    height: 100vw;
  }
}

.traffic {
  margin: 15px 10px 10px 10px;
  border: 1px dashed rgb(221, 41, 212);
  border-radius: 5px;
  padding: 5px 15px;
  b {
    display: block;
    padding: 15px 0px 9px 0px;
  }
  ul {
    margin-top: 0px;
    li {
      margin-top: 5px;
    }
  }
  b::before {
    content: '-';
    margin-right: 4px;
  }
  .navigate {
    display: flex;
    justify-content: space-evenly;
    button {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #ccc;
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    // border: 1px solid #eee;
    tr::first-line {
      text-align: center;
    }
    th,
    td {
      border-top: 1px solid #eee;
      padding: 5px;
    }
    // border-bottom: 1px solid #eee;
  }
}

.modal-dimming {
  z-index: -1;
  background-color: #00000022;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;

  transition: all 150ms ease-in-out;

  .modal {
    position: relative;
    -webkit-box-shadow: 3px 17px 21px -3px rgba(100, 100, 100, 0.61);
    box-shadow: 3px 17px 21px -3px rgba(100, 100, 100, 0.61);
    // display: flex;
    min-width: 50vw;
    min-height: 100px;
    max-width: 90vw;
    max-height: 90vh;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    // border: 0.5px solid #eee;
    .title {
      display: flex;
      height: 40px;
      align-items: center;
      padding: 0 20px;
    }
    .body {
      max-height: calc(90vh - 40px);
      overflow: auto;
    }
    .close {
      top: 0;
      right: 0;
      position: absolute;
      display: block;
      z-index: 100000;
      width: 40px;
      height: 40px;
      float: right;
      border: none;
      background-color: transparent;
      transform: rotate(45deg);
      align-items: center;
      justify-content: center;
    }
    .close::before,
    .close::after {
      // display: flex;
      position: absolute;
      content: ' ';
      display: inline-block;
      background-color: #333;
      border-radius: 1px;
    }
    .close::after {
      margin-top: -9px;
      width: 2px;
      height: 20px;
    }

    .close::before {
      margin-left: -9px;
      width: 20px;
      height: 2px;
    }
  }
}

.modal-dimming.open {
  z-index: 1000;
  opacity: 1;
}

.modal-dimming.close {
  z-index: 1000;
}

.modal.full {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 100vw;
  max-height: 100vh;
  .body {
    max-height: 100vh;
    overflow: auto;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  button {
    span {
      font-size: 2em;
      margin-left: 10px;
      color: #333;
      font-weight: 600;
      font-family: 'IBM Plex Sans KR', sans-serif;
    }
  }
  .seperator {
    margin-top: 20px;
    width: 1px;
    height: 30px;
    background-color: #ddd;
  }
  .groom,
  .bride {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
  }
  .groom::before,
  .bride::before {
    display: inline-block;
    width: 60px;
    height: 60px;
    background-color: #333;
    border-radius: 50%;
    text-align: center;
    line-height: 55px;
    font-size: 40px;
    margin-right: 5px;
  }
  .groom::before {
    content: '🤵🏻';
    background-color: rgb(154, 199, 128);
    border: 2px solid rgb(128, 197, 89);
    text-shadow: 2px 2px 4px #00000022;
  }
  .bride::before {
    content: '👰🏻‍♀️';
    background-color: rgb(245, 186, 253);
    border: 2px solid rgb(240, 152, 252);
    text-shadow: 2px 2px 4px #00000022;
  }
}

.tel-modal {
  padding: 10px 20px 10px 0px;
  li {
    a {
      text-decoration: none;
      color: #333;
    }
  }

  li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 10px !important;
    text-align: start !important;
    text-align-last: start !important;
  }
}

.photo-grid {
  display: flex;
  flex-wrap: wrap;
  div.cell {
    width: calc(100vw / 4);
    height: calc(100vw / 4);
    display: flex;
    img {
      width: calc(100vw / 4);
      height: calc(100vw / 4);
      object-fit: cover;
    }
    position: relative;
  }
  div.cell.active::after {
    content: ' ';
    @include absolute-fill();
    border: 3px dashed rgba(255, 149, 250, 0.8);
  }
}

.attend {
  display: flex;
  flex-direction: column;
  span {
    padding: 10px 30px;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
  }
  div {
    display: flex;
    justify-content: space-around;
    padding: 30px 20px 30px 20px;

    button {
      border-radius: 80px;
      width: 80px;
      height: 80px;
      padding: 0;
      border: 1px solid #ccc;
      // background-color: red;
      font-size: 20px;
      font-weight: 600;
      color: #666;
      position: relative;
    }

    button.selected {
      background-color: rgb(221, 41, 212);
      color: white;
      overflow: hidden;
    }
    .attend-configm.selected {
      background-color: rgb(221, 41, 212);
      color: white;
      overflow: hidden;
    }
    .attend-unknown.selected {
      background-color: rgb(182, 221, 41);
      color: white;
      overflow: hidden;
    }

    button.selected::before {
      content: ' ';
      position: absolute;
      display: block;
      height: 50px;
      width: 50px;
      border-radius: 50px;
      top: 15px;
      left: 30px;
      background-color: rgba(207, 207, 207, 0.2);
    }
    button.selected::after {
      content: ' ';
      position: absolute;
      display: block;
      height: 80px;
      width: 80px;
      border-radius: 80px;
      top: 35px;
      left: 0;
      background-color: rgba(207, 207, 207, 0.2);
    }
  }
}

.send {
  span {
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .copy {
    width: 100%;
    border: 0px transparent;
    height: 50px;
    background-color: #ddd;
    margin-bottom: 30px;
    font-weight: 600;
    color: #666;
    font-weight: 900;
    color: white;
  }
  .broom {
    background-color: rgb(154, 199, 128);
  }
  .bride {
    background-color: rgb(245, 186, 253);
  }
}
